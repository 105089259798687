import { ReactNode } from "react";
import FeaturebaseButton from "../../common/FeaturebaseButton";

interface Props {
    children: ReactNode;
}

const DefaultPage = ({children} : Props) => {
    return (
        <div className="relative min-h-screen">
            {children}
            <div className="fixed bottom-4 right-4 z-50">
                <FeaturebaseButton />
            </div>
        </div>
    )
}

export default DefaultPage