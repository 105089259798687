import React, { useEffect } from 'react';

interface FeaturebaseButtonProps {
    text?: string;
    organization?: string;
    theme?: 'light' | 'dark';
    placement?: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';
    email?: string;
    defaultBoard?: string;
    locale?: string;
    metadata?: any;
}

declare global {
    interface Window {
        Featurebase: any;
    }
}

const FeaturebaseButton: React.FC<FeaturebaseButtonProps> = ({ 
    organization = 'watskebeurt',
    theme = 'light',
    placement = 'bottom-right',
    email,
    defaultBoard,
    locale = 'nl',
    metadata = null
}) => {
    useEffect(() => {
        // Initialize Featurebase SDK
        (function(e: Window, t: Document){
            const a = "featurebase-sdk";
            function n(){
                const scriptElements = t.getElementsByTagName("script");
                if(!t.getElementById(a) && scriptElements.length > 0){
                    const firstScript = scriptElements[0];
                    if (firstScript.parentNode) {
                        const e = t.createElement("script");
                        e.id = a;
                        e.src = "https://do.featurebase.app/js/sdk.js";
                        firstScript.parentNode.insertBefore(e, firstScript);
                    }
                }
            }
            if(typeof e.Featurebase !== "function"){
                e.Featurebase = function(){
                    (e.Featurebase.q = e.Featurebase.q || []).push(arguments);
                };
            }
            if(t.readyState === "complete" || t.readyState === "interactive"){
                n();
            } else {
                t.addEventListener("DOMContentLoaded", n);
            }
        })(window, document);

        // Initialize the feedback widget
        window.Featurebase("initialize_feedback_widget", {
            organization,
            theme,
            placement,
            email,
            defaultBoard,
            locale,
            metadata
        });
    }, []); // Empty dependency array means this runs once on mount

    return <></>;
};

export default FeaturebaseButton;
