// @ts-nocheck
import React, {useEffect, useState} from 'react'
import {FetchPage} from "../../../Services/contenfulClient";
import {TypePage} from "../../../types/contentful";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { options } from './options.tsx'

interface Props {
    entry: string
}

export const ContentfulPageComponent: React.FC<Props> = ({entry}) => {
    const [content, setContent] = useState<TypePage|null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        console.log("ContentfulPageComponent")

        FetchPage(entry).then((entryObject) => {
            if (entryObject && entryObject.fields) {
                setContent(entryObject as TypePage)
                console.log(entryObject.fields.content)
                // console.log(JSON.stringify(entryObject))
            }
            setLoading(false)
        })
    }, [entry]);

    if (loading) {
        return (
            <div
                className="mt-16 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto lg:mt-24 p-10 bg-white rounded-md shadow">
                <div className="px-4 sm:px-0">
                    Page Loading...
                </div>
            </div>
        )
    }
    if (!content && !loading) {
        return (
            <div
                className="mt-16 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto lg:mt-24 p-10 bg-white rounded-md shadow">
                <div className="px-4 sm:px-0">
                    Page not found
                </div>
            </div>
        )
    }
    return (
        <div
            className="mt-16 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto lg:mt-24 p-10 bg-white rounded-md shadow dark:bg-gray-800">
            <div className="px-4 sm:px-0">
                <h1 className="text-3xl sm:text-4xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200 pb-10">{content.fields.pageTitle}</h1>
                <div className="prose dark:prose-invert max-w-none">
                    {documentToReactComponents(content?.fields.content, options)}
                </div>
            </div>
        </div>
    )
}

export default ContentfulPageComponent