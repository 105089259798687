import React from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';

export const options: Options = {
    renderMark: {
        [MARKS.BOLD]: (text: React.ReactNode) => (
            <strong className="font-bold text-gray-800">{text}</strong>
        ),
    },
    renderNode: {
        [BLOCKS.HEADING_1]: (_node: any, children: React.ReactNode) => (
            <h1 className="text-3xl font-bold mt-6 mb-4 dark:text-slate-200" id={children?.toString()}>{children}</h1>
        ),
        [BLOCKS.HEADING_2]: (_node: any, children: React.ReactNode) => (
            <h2 className="text-2xl font-semibold mt-4 mb-2 dark:text-slate-200" id={children?.toString()}>{children}</h2>
        ),
        [BLOCKS.PARAGRAPH]: (_node: any, children: React.ReactNode) => (
            <p className="mb-4 leading-relaxed text-gray-700 dark:text-white">{children}</p>
        ),
        [BLOCKS.UL_LIST]: (_node: any, children: React.ReactNode) => (
            <ul className="list-disc pl-5 space-y-2 dark:marker:text-gray-200">{children}</ul>
        ),
        [BLOCKS.OL_LIST]: (_node: any, children: React.ReactNode) => (
            <ol className="list-disc pl-5 space-y-2 dark:marker:text-gray-200">{children}</ol>
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { file, title, description } = node.data.target.fields;

            if (!file) {
                return null;
            }

            const url = file.url;
            const altText = description || title;

            return <img src={url} alt={altText} style={{ maxWidth: '100%', height: 'auto' }} />;
        },
        [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => {
            const { uri } = node.data;
            return (
                <a
                    href={uri}
                    className="truncate text-sm text-blue-500 dark:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {children}
                </a>
            );
        },
    },
};
